import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PrintMenuService} from "../../../../core/services/gestionmenus/print-menu.service";
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {BonAllotGroupingOrder} from "../../../../core/enums/bon-allotissement-grouping-enum";
import {BonAllotissementSortingEnum} from "../../../../core/enums/bon-allotissement-sorting-enum";

@Component({
  selector: 'yo-bon-allotissement',
  templateUrl: './bon-allotissement.component.html',
  styleUrls: ['./bon-allotissement.component.scss']
})

export class BonAllotissementComponent implements OnInit, OnDestroy {

  @Input() public idPlanProduction: number;
  @Input() public idPlanProductionDetails: number[];
  displayDialog: boolean = false;
  defaultOrder: boolean = true;
  sorting: BonAllotissementSortingEnum = BonAllotissementSortingEnum.DATE_LIVRAISON;
  formatPrintingOptions: any[] = [{ label: 'Tournée / Plat / Plc', value: this.defaultOrder }, { label: 'Plat / Tournée / Plc', value: !this.defaultOrder }];
  sortPrintingOptions: any[] = [{ label: 'Date de livraison', value: BonAllotissementSortingEnum.DATE_LIVRAISON }, { label: 'Date de fabrication', value: BonAllotissementSortingEnum.DATE_FABRICATION }];

  totalAndTraca: boolean = false;

  constructor(
    private printMenuService: PrintMenuService,
    private pcSvc: PreparationConditionnementService) {
  }

  ngOnInit(): void {
    this.printMenuService.displayDialogPrintBonAllotissement$.subscribe(() => {
      this.displayDialog = true;
      this.defaultOrder = true;
      this.totalAndTraca = false;
    });
  }

  ngOnDestroy(): void {
    this.displayDialog = false;
  }

  downloadBonAllotissement = (): void => {
    const grouping: BonAllotGroupingOrder = this.defaultOrder ? BonAllotGroupingOrder.TOURNEE_PLAT_PLC : BonAllotGroupingOrder.PLAT_TOURNEE_PLC;
    this.pcSvc.printBonAllotissement(this.idPlanProduction, grouping, this.idPlanProductionDetails, false, this.totalAndTraca, this.sorting);
    this.displayDialog = false;
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.defaultOrder = true;
    this.sorting = BonAllotissementSortingEnum.DATE_LIVRAISON;
  }

}
