// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11020(702a265637)-C13/12/2024-10:41:42-B13/12/2024-10:46:00' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11020(702a265637)-C13/12/2024-10:41:42-B13/12/2024-10:46:00",
  branch: "master",
  latestTag: "6.1",
  revCount: "11020",
  shortHash: "702a265637",
  longHash: "702a265637de1cb82779a3c107931cda9266436a",
  dateCommit: "13/12/2024-10:41:42",
  dateBuild: "13/12/2024-10:46:00",
  buildType: "Ansible",
  } ;
