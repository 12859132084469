<dx-popup
  [showTitle]="true"
  title="Edition des bons d'allotissement "
  [(visible)]="displayDialog"
  [width]="400"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <div class="settings">
  <div class="dialog-line">
    <div class="print-settings">
      <span>ORDRE D'IMPRESSION</span>
      <dx-radio-group [(value)]="defaultOrder" valueExpr="value" displayExpr="label"
                      [items]="formatPrintingOptions"></dx-radio-group>
    </div>
  </div>

  <div class="dialog-line">
    <div class="print-settings">
      <span class="strong">OPTION D'IMPRESSION</span>
      <dx-check-box
        [(ngModel)]="totalAndTraca" text="Totaux / Traçabilité"
        [value]="false">
      </dx-check-box>
    </div>
  </div>

  <div class="dialog-line">
    <div class="print-settings">
      <div> DATE DE TRI</div>
      <dx-radio-group [(value)]="sorting" valueExpr="value" displayExpr="label"
                      [items]="sortPrintingOptions"></dx-radio-group>
    </div>
  </div>

  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="downloadBonAllotissement()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>
  </div>

</dx-popup>
