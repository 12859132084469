import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'yo-lb',
  templateUrl: './lb.component.html',
  styleUrls: ['./lb.component.scss']
})
export class LbComponent implements OnInit {

  lbStatuts: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.lbStatuts = [
      {label:'LISTES DE BESOINS', icon:'fas fa-shopping-basket', routerLink:['listes-de-besoins']},
    ];
  }
}
